import React from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as Plus } from '@/svg/plus.svg';
import { ReactComponent as More } from '@/svg/more.svg';
import { ReactComponent as SlidersHorizontal } from '@/svg/sliders-horizontal.svg';
import { ReactComponent as Close } from '@/svg/close.svg';
import { ReactComponent as Success } from '@/svg/success.svg';
import { ReactComponent as Warning } from '@/svg/warning.svg';
import { ReactComponent as Error } from '@/svg/error.svg';
import { ReactComponent as Check } from '@/svg/check.svg';
import { ReactComponent as Edit } from '@/svg/edit.svg';
import { ReactComponent as Delete } from '@/svg/delete.svg';
import { ReactComponent as ChevronRight } from '@/svg/chevron-right.svg';
import { ReactComponent as ChevronLeft } from '@/svg/chevron-left.svg';
import { ReactComponent as ChevronDoubleRight } from '@/svg/chevron-double-right.svg';
import { ReactComponent as ChevronDoubleLeft } from '@/svg/chevron-double-left.svg';
import { ReactComponent as CaretSort } from '@/svg/caret-sort.svg';
import { ReactComponent as ArrowUp } from '@/svg/arrow-up.svg';
import { ReactComponent as ArrowDown } from '@/svg/arrow-down.svg';
import { ReactComponent as EyeOff } from '@/svg/eye-off.svg';
import { ReactComponent as Circle } from '@/svg/circle.svg';
import { ReactComponent as ArrowUpCircle } from '@/svg/arrow-up-circle.svg';
import { ReactComponent as CheckCircle } from '@/svg/check-circle.svg';
import { ReactComponent as CloseCircle } from '@/svg/close-circle.svg';
import { ReactComponent as Clock } from '@/svg/clock.svg';
import { ReactComponent as Search } from '@/svg/search.svg';
import { ReactComponent as Info } from '@/svg/info.svg';

import { ReactComponent as Dashboard } from '@/svg/dashboard.svg';
import { ReactComponent as Policies } from '@/svg/policies.svg';
import { ReactComponent as Renewals } from '@/svg/renewals.svg';
import { ReactComponent as Clients } from '@/svg/clients.svg';
import { ReactComponent as Claims } from '@/svg/claims.svg';
import { ReactComponent as Complaints } from '@/svg/complaints.svg';
import { ReactComponent as Tasks } from '@/svg/tasks.svg';
import { ReactComponent as ThirdParties } from '@/svg/3rd-parties.svg';
import { ReactComponent as Reporting } from '@/svg/reporting.svg';
import { ReactComponent as Manager } from '@/svg/manager.svg';
import { ReactComponent as Admin } from '@/svg/admin.svg';
import { ReactComponent as ArrowLeft } from '@/svg/arrow-left.svg';
import { ReactComponent as Notification } from '@/svg/notification.svg';
import { ReactComponent as Card } from '@/svg/card.svg';
import { ReactComponent as Bacs } from '@/svg/bacs.svg';
import { ReactComponent as Cheque } from '@/svg/cheque.svg';
import { ReactComponent as DocumentUpload } from '@/svg/document-upload.svg';
import { ReactComponent as DocumentText } from '@/svg/document-text.svg';
import { ReactComponent as Drag } from '@/svg/drag.svg';
import { ReactComponent as User } from '@/svg/user.svg';
import { ReactComponent as History } from '@/svg/history.svg';
import { ReactComponent as SignOut } from '@/svg/sign-out.svg';
import { ReactComponent as ChevronDown } from '@/svg/chevron-down.svg';
import { ReactComponent as Spinner } from '@/svg/spinner.svg';
import { ReactComponent as Archive } from '@/svg/archive.svg';
import { ReactComponent as ThickDelete } from '@/svg/thick-delete.svg';
import { ReactComponent as FloppyDisk } from '@/svg/floppy-disk.svg';
import { ReactComponent as Copy } from '@/svg/copy.svg';
import { ReactComponent as Upload } from '@/svg/upload.svg';
import { ReactComponent as NumberOne } from '@/svg/number-one.svg';
import { ReactComponent as Person } from '@/svg/person.svg';
import { ReactComponent as Tick } from '@/svg/tick.svg';
import { ReactComponent as IndustryExperts } from '@/svg/industry-experts.svg';
// Note: clamis.svg already exist, so name this svg file as claims-phone.svg
import { ReactComponent as ClaimsPhone } from '@/svg/claims-phone.svg';
import { ReactComponent as Simple } from '@/svg/simple.svg';
import { ReactComponent as Dependable } from '@/svg/dependable.svg';
import { ReactComponent as Online } from '@/svg/online.svg';
import { ReactComponent as Calendar } from '@/svg/calendar.svg';
import { ReactComponent as AdditionalEquip } from '@/svg/additional-equip.svg';
import { ReactComponent as BodilyInjury } from '@/svg/bodily-injury.svg';
import { ReactComponent as HorseRiding } from '@/svg/horse-riding.svg';
import { ReactComponent as Gymnastics } from '@/svg/gymnastics.svg';
import { ReactComponent as Graph } from '@/svg/graph.svg';
import { ReactComponent as Plane } from '@/svg/plane.svg';
import { ReactComponent as Court } from '@/svg/court.svg';
import { ReactComponent as Love } from '@/svg/love.svg';
import { ReactComponent as Contracts } from '@/svg/contracts.svg';
import { ReactComponent as Theft } from '@/svg/theft.svg';
import { ReactComponent as Weather } from '@/svg/weather.svg';
import { ReactComponent as Time } from '@/svg/time.svg';
import { ReactComponent as ArrowRightCircle } from '@/svg/arrow-right-circle.svg';
import { ReactComponent as BackClock } from '@/svg/back-clock.svg';
import { ReactComponent as Coins } from '@/svg/coins.svg';
import { ReactComponent as Eye } from '@/svg/eye.svg';
import { ReactComponent as Mail } from '@/svg/mail.svg';
import { ReactComponent as BlueChevronRight } from '@/svg/blue-chevron-right.svg';
import { ReactComponent as Phone } from '@/svg/phone.svg';
import { ReactComponent as Location } from '@/svg/location.svg';
import { ReactComponent as HighVis } from '@/svg/high-vis.svg';
import { ReactComponent as Chat } from '@/svg/chat.svg';
import { ReactComponent as Treatment } from '@/svg/treatment.svg';
import { ReactComponent as ClipboardSuccess } from '@/svg/clipboard-success.svg';
import { ReactComponent as ClipboardNegative } from '@/svg/clipboard-negative.svg';
import { ReactComponent as Elderley } from '@/svg/elderley.svg';
import { ReactComponent as WarningTriangle } from '@/svg/warning-triangle.svg';
import { ReactComponent as Vulnerable } from '@/svg/vulnerable.svg';

function Icon({ name, className, onPointerDown }) {
	switch (name) {
		default:
			return <Plus className={className} />;

		case 'plus':
			return <Plus className={className} />;

		case 'more':
			return <More className={className} />;

		case 'sliders-horizontal':
			return <SlidersHorizontal className={className} />;

		case 'close':
			return <Close className={className} />;

		case 'success':
			return <Success className={className} />;

		case 'warning':
			return <Warning className={className} />;

		case 'error':
			return <Error className={className} />;

		case 'info':
			return <Info className={className} />;

		case 'check':
			return <Check className={className} />;

		case 'edit':
			return <Edit className={className} />;

		case 'delete':
			return <Delete className={className} />;

		case 'chevron-right':
			return <ChevronRight className={className} />;

		case 'chevron-left':
			return <ChevronLeft className={className} />;

		case 'chevron-down':
			return <ChevronDown className={className} />;

		case 'chevron-double-right':
			return <ChevronDoubleRight className={className} />;

		case 'chevron-double-left':
			return <ChevronDoubleLeft className={className} />;

		case 'caret-sort':
			return <CaretSort className={className} />;

		case 'arrow-up':
			return <ArrowUp className={className} />;

		case 'arrow-down':
			return <ArrowDown className={className} />;

		case 'eye-off':
			return <EyeOff className={className} />;

		case 'circle':
			return <Circle className={className} />;

		case 'arrow-up-circle':
			return <ArrowUpCircle className={className} />;

		case 'check-circle':
			return <CheckCircle className={className} />;

		case 'close-circle':
			return <CloseCircle className={className} />;

		case 'clock':
			return <Clock className={className} />;

		case 'search':
			return <Search className={className} />;

		case 'dashboard':
			return <Dashboard className={className} />;

		case 'policies':
			return <Policies className={className} />;

		case 'renewals':
			return <Renewals className={className} />;

		case 'clients':
			return <Clients className={className} />;

		case 'claims':
			return <Claims className={className} />;

		case 'complaints':
			return <Complaints className={className} />;

		case 'tasks':
			return <Tasks className={className} />;

		case 'third-parties':
			return <ThirdParties className={className} />;

		case 'reporting':
			return <Reporting className={className} />;

		case 'manager':
			return <Manager className={className} />;

		case 'admin':
			return <Admin className={className} />;

		case 'arrow-left':
			return <ArrowLeft className={className} />;

		case 'notification':
			return <Notification className={className} />;

		case 'card':
			return <Card className={className} />;

		case 'bacs':
			return <Bacs className={className} />;

		case 'cheque':
			return <Cheque className={className} />;

		case 'document-upload':
			return <DocumentUpload className={className} />;

		case 'document-text':
			return <DocumentText className={className} />;

		case 'drag':
			return <Drag className={className} onPointerDown={onPointerDown} />;

		case 'user':
			return <User className={className} />;

		case 'history':
			return <History className={className} />;

		case 'sign-out':
			return <SignOut className={className} />;

		case 'spinner':
			return <Spinner className={className} />;

		case 'archive':
			return <Archive className={className} />;

		case 'thick-delete':
			return <ThickDelete className={className} />;

		case 'floppy-disk':
			return <FloppyDisk className={className} />;

		case 'copy':
			return <Copy className={className} />;

		case 'upload':
			return <Upload className={className} />;

		case 'number-one':
			return <NumberOne className={className} />;

		case 'person':
			return <Person className={className} />;

		case 'tick':
			return <Tick className={className} />;

		case 'industry-experts':
		return <IndustryExperts className={className} />;

		// Note: clamis.svg already exist, so name this svg file as claims-phone.svg
		case 'claims-phone':
			return <ClaimsPhone className={className} />;

		case 'simple':
			return <Simple className={className} />;

		case 'dependable':
			return <Dependable className={className} />;

		case 'online':
			return <Online className={className} />;

		case 'calendar':
		return <Calendar className={className} />;

		case 'additional-equip':
		return <AdditionalEquip className={className} />;

		case 'bodily-injury':
			return <BodilyInjury className={className} />;
			
		case 'horse-riding':
			return <HorseRiding className={className} />;

		case 'gymnastics':
			return <Gymnastics className={className} />;

		case 'graph':
			return <Graph className={className} />;

		case 'plane':
		return <Plane className={className} />;

		case 'court':
		return <Court className={className} />;

		case 'love':
		return <Love className={className} />;

		case 'contracts':
		return <Contracts className={className} />;

		case 'theft':
		return <Theft className={className} />;

		case 'weather':
		return <Weather className={className} />;

		case 'time':
		return <Time className={className} />;

		case 'location':
		return <Location className={className} />;

		case 'high-vis':
		return <HighVis className={className} />;

		case 'chat':
		return <Chat className={className} />;

		case 'treatment':
		return <Treatment className={className} />;

		case 'clipboard-success':
		return <ClipboardSuccess className={className} />;

		case 'clipboard-negative':
		return <ClipboardNegative className={className} />;

		case 'elderly':
		return <Elderley className={className} />;

		case 'arrow-right-circle':
			return <ArrowRightCircle className={className} />;

		case 'back-clock':
			return <BackClock className={className} />;

		case 'coins':
			return <Coins className={className} />;

		case 'eye':
			return <Eye className={className} />;

		case 'mail':
			return <Mail className={className} />;

		case 'blue-chevron-right':
			return <BlueChevronRight className={className} />;

		case 'phone':
			return <Phone className={className} />;

		case 'warning-triangle':
			return <WarningTriangle className={className} />;

		case 'vulnerable':
			
			// return <Vulnerable className={className} />;
			return <Info className={className} />;
	}
}

export default Icon;
Icon.propTypes = {
	name: PropTypes.string.isRequired,
	className: PropTypes.string,
	onPointerDown: PropTypes.func,
};

Icon.defaultProps = {
	name: null,
	className: null,
	onPointerDown: null,
};
